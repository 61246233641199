(function () {
    'use strict';

    angular
        .module("myApp")
        .factory('EventService', ['$resource','domainUrlApi', function ($resource,domainUrlApi) {
            var data = $resource(domainUrlApi+'api/c/event/:id', { id: '@id'});
            return data;
        }]);

})();