(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('HomeController', ['$scope','$translate','$state','anchorSmoothScroll', function ($scope, $translate, $state, anchorSmoothScroll) {

      // $scope.changeLang = function changeLangFn(langKey) {
      //   console.log("lang", langkey)
      //   $translate.use(langKey);
      // };
      $scope.promotionSpinner = false;
      $scope.customer_form = true;
      $scope.promotionThankyou = false;
      $scope.myInterval = 5000;
      $scope.noWrapSlides = false;
      $scope.active = 0;
      var slides = $scope.slides = [];
      var currIndex = 0;

      $scope.addSlide = function () {
        var newWidth = 600 + slides.length + 1;
        slides.push({
          image: '//unsplash.it/' + newWidth + '/700',
          text: ['ตัวช่วยที่ดีจะทำให้ธุรกิจคุณเติบโตได้เร็วยิ่งขึ้น ', 'Awesome photograph', 'That is so cool', 'I love that'][slides.length % 4],
          id: currIndex++
        });
      };

      $scope.randomize = function () {
        var indexes = generateIndexesArray();
        assignNewIndexesToSlides(indexes);
      };

      // for (var i = 0; i < 4; i++) {
      //   $scope.addSlide();
      // }

      // Randomize logic below

      function assignNewIndexesToSlides(indexes) {
        for (var i = 0, l = slides.length; i < l; i++) {
          slides[i].id = indexes.pop();
        }
      }

      function generateIndexesArray() {
        var indexes = [];
        for (var i = 0; i < currIndex; ++i) {
          indexes[i] = i;
        }
        return shuffle(indexes);
      }

      // http://stackoverflow.com/questions/962802#962890
      function shuffle(array) {
        var tmp, current, top = array.length;

        if (top) {
          while (--top) {
            current = Math.floor(Math.random() * (top + 1));
            tmp = array[current];
            array[current] = array[top];
            array[top] = tmp;
          }
        }

        return array;
      }

      $scope.gotoElement = function (eID) {
        anchorSmoothScroll.scrollTo(eID);
      };

      slides.push({
        image: '/resources/assets/images/resources/website_1920.jpg',
        text: ['Nice image', 'Awesome photograph', 'That is so cool', 'I love that'][slides.length % 4],
        id: currIndex++
      });
      slides.push({
        image: '/resources/assets/images/resources/mobile_1920.jpg',
        text: ['Nice image', 'Awesome photograph', 'That is so cool', 'I love that'][slides.length % 4],
        id: currIndex++
      });

      $scope.sendMessage = function () {
        var firebase_setting = { 
            apiKey: "AIzaSyCBrPUd-mfBM1jLusEI5SvyzKxGyWzzIGI",
            authDomain: "upbrand-asia.firebaseio.com",
            databaseURL: "https://upbrand-asia.firebaseio.com/",
            storageBucket: "upbrand-asia.appspot.com",
            messagingSenderId: "454533834929" 
            };
        if (!firebase.apps.length) {
            firebase.initializeApp(firebase_setting);
        }
        console.log($scope.promotionCheckbox);
        var d = new Date();
        var firebase_database = firebase.database().ref("Promotion Form " + d + " คุณ " + $scope.customer_name);
        var customer = { type: $scope.promotionCheckbox, name: $scope.customer_name, email: $scope.customer_email, phone: $scope.customer_phone, message: $scope.customer_message};
        $scope.promotionSpinner = true;
        $scope.customer_form = false;
        firebase_database.set(customer, function(error) {
          if (error) {
            // The write failed...
          } else {
            $scope.promotionSpinner = false;
            $scope.promotionThankyou = true;
          }
        });
        $scope.promotionSpinner = false;
        $scope.promotionThankyou = true;
      };
    

    }])

})();