(function () {
  'use strict';
  angular
    .module("myApp", ['ngResource', 'pascalprecht.translate', 'ngCookies', 'ui.router', 'angulartics', 'angulartics.google.analytics', 'particles', 'ui.bootstrap', 'rev.slider'])
    .constant('urlservice', 'https://www.jobsmatcher.com/')
    .constant('domainUrlApi', 'http://localhost:8080/')
    .config(['$stateProvider', '$translateProvider', '$httpProvider', '$locationProvider', '$urlRouterProvider', '$logProvider', '$resourceProvider', 'domainUrlApi', function ($stateProvider, $translateProvider, $httpProvider, $locationProvider, $urlRouterProvider, $logProvider, $resourceProvider, domainUrlApi) {
      // $logProvider.debugEnabled(false);
      $stateProvider
        .state('baseSlide', {
          abstract: true,
          // templateUrl: 'pages/base/baseBasic.html',
          views: {
            'header': {
              templateUrl: 'pages/commons/header.html',
              controller: "HeaderController"
            },

            'revSlider': {
              templateUrl: 'pages/base/revSlider.html',
              controller: "RevSliderController"
            },
            'baseBasic': {
              templateUrl: 'pages/base/baseBasic.html',
              // controller: "JobController"
            },
            'customerContact': {
              templateUrl: 'pages/commons/customerContact.html',
              controller: "CustomerContactController"
            },
            'footer': {
              templateUrl: 'pages/commons/footer.html'
              // controller: "HomeController"
            }
          }
        })
        .state('baseBasic', {
          abstract: true,
          // templateUrl: 'pages/base/baseBasic.html',
          views: {
            'header': {
              templateUrl: 'pages/commons/header.html',
              controller: "HeaderController"
            },
            'baseBasic': {
              templateUrl: 'pages/base/baseBasic.html',
              // controller: "JobController"
            },
            'customerContact': {
              templateUrl: 'pages/commons/customerContact.html',
              controller: "CustomerContactController"
            },
            'footer': {
              templateUrl: 'pages/commons/footer.html'
              // controller: "HomeController"
            }
          }
        })
        .state("baseSlide.home", {
          url: "/",
          views: {
            'body': {
              templateUrl: 'pages/home/main.html',
              controller: "HomeController"
            }
          }
        })
        .state("baseBasic.pricing", {
          url: "/pricing",
          views: {
            'body': {
              templateUrl: 'pages/pricing/pricing.html',
              // controller: "PricingController"
            }
          }
        })
        .state("baseBasic.web", {
          url: "/service/web",
          views: {
            'body': {
              templateUrl: 'pages/service/web.html',
              controller: "WebController"
            }
          }
        })
        .state("baseBasic.mobile", {
          url: "/service/mobile",
          views: {
            'body': {
              templateUrl: 'pages/service/mobile.html',
              // controller: "MobileController"
            }
          }
        })
        .state("baseBasic.onlinemarketing", {
          url: "/service/onlinemarketing",
          views: {
            'body': {
              templateUrl: 'pages/service/onlinemarketing.html',
              // controller: "JobController"
            }
          }
        })
        .state("baseBasic.contactUs", {
          url: "/contact/contactUs",
          views: {
            'body': {
              templateUrl: 'pages/contact/contactUs.html',
              controller: "ContactController"
            }
          }
        })
        .state("baseBasic.onlinegoogle", {
          url: "/service/onlinemarketing/google",
          views: {
            'body': {
              templateUrl: 'pages/service/onlinemarketing-google.html'
            }
          }
        })
        .state("baseBasic.onlinefacebook", {
          url: "/service/onlinemarketing/facebook",
          views: {
            'body': {
              templateUrl: 'pages/service/onlinemarketing-facebook.html'
            }
          }
        })
        .state("baseBasic.onlineinstagram", {
          url: "/service/onlinemarketing/instagram",
          views: {
            'body': {
              templateUrl: 'pages/service/onlinemarketing-instagram.html'
            }
          }
        })
        .state("baseBasic.portfolio", {
          url: "/portfolio",
          views: {
            'body': {
              templateUrl: 'pages/portfolio/portfolio.html'
            }
          }
        })
        .state("baseBasic.portfolioindustry", {
          url: "/portfolio/industry",
          views: {
            'body': {
              templateUrl: 'pages/portfolio/portfolio-detail-industry.html'
            }
          }
        })
        .state("baseBasic.portfolioservice", {
          url: "/portfolio/service",
          views: {
            'body': {
              templateUrl: 'pages/portfolio/portfolio-detail-service.html'
            }
          }
        })
        .state("baseBasic.portfolioecommerce", {
          url: "/portfolio/ecommerce",
          views: {
            'body': {
              templateUrl: 'pages/portfolio/portfolio-detail-ecom.html'
            }
          }
        })
        .state('personal', {
          url: "/personal",
          templateUrl: 'pages/profile/personal.html'
        });

      $urlRouterProvider.otherwise('/');


      // $translateProvider.useSanitizeValueStrategy(null);
      // $translateProvider.useLoader('$translatePartialLoader', {
      //   urlTemplate: domainUrlApi + 'label/{lang}'
      // });
      // $translatePartialLoaderProvider.addPart('main');
      $translateProvider.useStaticFilesLoader({
        prefix: 'resources/lang/',
        suffix: '.json'
      })
      $translateProvider.useLocalStorage();
      $translateProvider.preferredLanguage('th');

      $httpProvider.defaults.withCredentials = true;
      $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

      $resourceProvider.defaults.stripTrailingSlashes = false;

      $resourceProvider.defaults.actions = {
        'get': { method: 'GET' },
        'save': { method: 'POST', headers: { 'Content-Type': 'application/json;charset=UTF-8' } },
        'query': { method: 'GET', isArray: false },
        'remove': { method: 'DELETE' },
        'delete': { method: 'DELETE' }
      };
    }])
    .filter('image_company', ['domainUrlApi', function (domainUrlApi) {

      return function (input) {
        if (input != null) {
          return domainUrlApi + "resource/thumbnail/200x200" + input;
        }
        else {
          return 'resource/img/icon_corporate.jpg';
        }
      }
    }])
    .directive("directiveWhenScrolled", ['$log', function ($log) {
      $log.debug('directiveWhenScrolled');
      return function (scope, elm, attr) {
        var raw = elm[0];
        $log.debug('directiveWhenScrolled', raw);
        elm.bind('scroll', function () {
          if (raw.scrollTop + (1.7 * raw.offsetHeight) >= raw.scrollHeight) {
            $log.debug('call', attr.directiveWhenScrolled);
            $log.debug('$scope', scope);
            scope.$apply(attr.directiveWhenScrolled);
          }
        });
      };
    }])
    .directive('setClassWhenAtTop', ['$log', '$window', function ($log, $window) {
      var $win = angular.element($window); // wrap window object as jQuery object

      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          var topClass = attrs.setClassWhenAtTop, // get CSS class from directive's attribute value
            topPadding = parseInt(attrs.paddingWhenAtTop, 10),
            offsetTop = element.prop('offsetTop'); // get element's offset top relative to document

          $win.on('scroll', function (e) {
            if ($window.pageYOffset + topPadding <= offsetTop) {
              element.addClass(topClass);
            } else {
              element.removeClass(topClass);
            }
          });
        }
      };
    }])
    .directive("dlEnterKey", ['$log', function ($log) {
      $log.debug('dlEnterKey');
      return function (scope, element, attrs) {
        $log.debug('dlEnterKey', element);
        element.bind("keydown keypress", function (event) {
          var keyCode = event.which || event.keyCode;

          // If enter key is pressed
          if (keyCode === 13) {
            scope.$apply(function () {
              // Evaluate the expression
              scope.$eval(attrs.dlEnterKey);
            });

            event.preventDefault();
          }
        });
      };
    }])
    .service('anchorSmoothScroll', function () {

      this.scrollTo = function (eID) {

        // This scrolling function 
        // is from http://www.itnewb.com/tutorial/Creating-the-Smooth-Scroll-Effect-with-JavaScript

        var startY = currentYPosition();
        var stopY = elmYPosition(eID);
        var distance = stopY > startY ? stopY - startY : startY - stopY;
        if (distance < 100) {
          scrollTo(0, stopY); return;
        }
        var speed = Math.round(distance / 100);
        if (speed >= 20) speed = 20;
        var step = Math.round(distance / 25);
        var leapY = stopY > startY ? startY + step : startY - step;
        var timer = 0;
        if (stopY > startY) {
          for (var i = startY; i < stopY; i += step) {
            setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
            leapY += step; if (leapY > stopY) leapY = stopY; timer++;
          } return;
        }
        for (var i = startY; i > stopY; i -= step) {
          setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
          leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
        }

        function currentYPosition() {
          // Firefox, Chrome, Opera, Safari
          if (self.pageYOffset) return self.pageYOffset;
          // Internet Explorer 6 - standards mode
          if (document.documentElement && document.documentElement.scrollTop)
            return document.documentElement.scrollTop;
          // Internet Explorer 6, 7 and 8
          if (document.body.scrollTop) return document.body.scrollTop;
          return 0;
        }

        function elmYPosition(eID) {
          var elm = document.getElementById(eID);
          var y = elm.offsetTop;
          var node = elm;
          while (node.offsetParent && node.offsetParent != document.body) {
            node = node.offsetParent;
            y += node.offsetTop;
          } return y;
        }

      };

    })
    .run(['$document', '$transitions', '$http', '$rootScope', '$cookies', '$translate', '$state', 'domainUrlApi', function ($document, $transitions, $http, $rootScope, $cookies, $translate, $state, domainUrlApi) {
      // var req = {
      //   method: 'GET',
      //   url: domainUrlApi+'api/userinfo',
      //   withCredentials: true
      // }
      // $http(req).
      //   then(function (response) {
      //     $rootScope.userinfo = response.data;
      //   });
      //create a new instance

      new WOW().init();

      $transitions.onStart({}, function (transition) {
        $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
        new WOW().sync();
      });

      // var lang = $cookies.get("LCODE");
      // if (lang === undefined) {
      //   lang = "th_TH";
      // }

      $rootScope.menu = [
        {
          label: "Home"
          , state: "baseSlide.home"
        } 
        // , {
        //   label: "PRICING"
        //   , state: "baseBasic.pricing"
        // }
        , {
          label: "Service"
          , children: [
            {
              label: "Website"
              , state: "baseBasic.web"
            }
            , {
              label: "Mobile"
              , state: "baseBasic.mobile"
            }
            , {
              label: "Online Marketing"
              , state: "baseBasic.onlinemarketing"
            }
          ]
        }
        , {
          label: "Contact"
          , children: [
            // {
            //   label: "About Us"
            //   , state: "baseBasic.aboutUs"
            // },
            {
              label: "Contact Us"
              , state: "baseBasic.contactUs"
            }
          ]
        }

      ];
      // $translate.use(lang);
    }])

})();