(function () {
    'use strict';

    angular
        .module('myApp')
        .controller('HeaderController', ['$log','$scope','$translate','$uibModal' , function ($log,$scope,$translate,$uibModal) {
            // var vm = this;

            $scope.changeLang = function changeLangFn(langKey) {
              $translate.use(langKey);
            };
      

            $scope.isCollapsed = true;
            $scope.status = {
              isopen: false
            }
      
            $scope.lineQrOpen = function (size) {
              var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: '/pages/commons/lineQrModal.html',
                controller: 'HeaderController',
                controllerAs: 'pc',
                size: size
              });}
        }])

})();