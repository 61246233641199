(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('CustomerContactController', ['$scope','$translate','$state', function ($scope, $translate, $state) {

      $scope.spinner = false;
      $scope.customer_form = true;
      $scope.thankyou = false;
      $scope.customer_type = 'not_select'
      $scope.sendMessage = function () {
        var firebase_setting = { 
            apiKey: "AIzaSyCBrPUd-mfBM1jLusEI5SvyzKxGyWzzIGI",
            authDomain: "upbrand-asia.firebaseio.com",
            databaseURL: "https://upbrand-asia.firebaseio.com/",
            storageBucket: "upbrand-asia.appspot.com",
            messagingSenderId: "454533834929" 
            };
        if (!firebase.apps.length) {
            firebase.initializeApp(firebase_setting);
        }
        var d = new Date();
        var firebase_database = firebase.database().ref("Customer Form " + d + " คุณ " + $scope.customer_name);
        var customer = { type: $scope.customer_type, name: $scope.customer_name, email: $scope.customer_email, phone: $scope.customer_phone, message: $scope.customer_message};
        $scope.spinner = true;
        $scope.customer_form = false;
        firebase_database.set(customer, function(error) {
          if (error) {
            // The write failed...
          } else {
            $scope.spinner = false;
            $scope.thankyou = true;
          }
        });
        $scope.spinner = false;
        $scope.thankyou = true;
      };
    }])
})();